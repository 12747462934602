import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogFR from "../locales/fr/messages"
import Layout from "../components/layout"
import { FaLink, FaMapMarkerAlt } from 'react-icons/fa';
import cto from "../assets/images/home/cto.png"
import cfo from "../assets/images/home/cfo.png"
import coo from "../assets/images/home/coo.png"
import ceo from "../assets/images/home/ceo.png"
import BlaBlaCar from "../assets/images/home/BlaBlaCar.png"
import shadow from "../assets/images/home/shadow.png"
import meero from "../assets/images/home/meero.png"


export default ({ data }) => (
  <I18nProvider
    language="fr"
    catalogs={{
      fr: catalogFR,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="fr"
      title="Startupedia, l'encyclopédie des startups"
      description="Startupedia fournit toutes les informations nécessaires sur les start-ups et les entrepreneurs avec des nouvelles liées au monde des start-ups"
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
      <div className="wrapper mt-3">
      <div className="container">
          <h1>L'encyclopédie des start-ups et de l'entreprenariat</h1>
          <p>Startup est un mot qui vient de l'anglais et dont le sens littéral est "mise en mouvement". Mais qu'est-ce exactement qu'une Startup, quels types de Startups existe-t-il ... Toutes ces questions et bien d'autres encore à suivre.</p>
          
          <h2 className="pt-3">Qu'est-ce qu'une Startup ?</h2>
          <p>Le mot "Startup" vient de l'anglais et sa traduction littérale est "start-up". Bien qu'il n'existe pas de définition officielle, on pourrait dire qu'une Startup est une entreprise ou une initiative entrepreneuriale présentant les caractéristiques suivantes :
            <ul>
              <li>est basé sur la technologie</li>
              <li>est modulable</li>
              <li>fonctionne dans un environnement d'incertitude maximale</li>
            </ul>
            Outre les points ci-dessus, une jeune entreprise se distingue d'une entreprise ou d'une PME classique par son dynamisme et sa rapidité d'adaptation aux changements. La création de ce type d'entreprises technologiques a donné naissance au concept de "startupisme" ou d'entrepreneuriat qui n'est rien d'autre que tout ce qui tourne autour de ce monde de création d'entreprises qui révolutionnent le secteur dans lequel elles apparaissent. Cliquez ici pour en savoir plus sur ce <Link to="/fr/quest-ce-que/startup/">que sont les startups</Link>.
          </p>

          <h2 className="pt-3">Rôles et responsabilités dans une startup</h2>
          <p>Deux ou trois fondateurs aux profils différents font partie de la fondation, ou constitution, de ce type de société. Chacun d'entre eux apporte son expertise pour assurer le succès de l'entreprise. Vous trouverez ci-dessous les rôles typiques dans une Startup.</p>
          <div className="row">
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/fr/quest-ce-que/ceo-directeur-general-chief-executive-officer/"><img src={ceo} alt="CEO" title="CEO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/fr/quest-ce-que/cto-directeur-de-la-technologie-chief-technology-officer/"><img src={cto} alt="CTO" title="CTO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/fr/quest-ce-que/cfo-directeur-financier-chief-financial-officer/"><img src={cfo} alt="CFO" title="CFO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/fr/quest-ce-que/coo-directeur-de-l-exploitation-chief-operating-officer/"><img src={coo} alt="COO" title="COO" /></Link>
            </div>
          </div>
          
          <h2 className="pt-3">Lean Startup</h2>
          <p>La méthode Lean Startup a été créée par Eric Ries et développée dans son livre "The Lean Startup". Cette méthodologie est utilisée par des entreprises du monde entier pour aborder de nouvelles idées commerciales, comprendre le marché et savoir comment le développer. Il vise à raccourcir les cycles de développement des produits et à découvrir rapidement si un modèle commercial proposé est viable. Pour ce faire, on adopte une combinaison d'expérimentations fondées sur des hypothèses commerciales, de lancements de produits itératifs et d'apprentissage validé. Aujourd'hui, il est très difficile de lever des fonds, surtout au début, sans comprendre et mettre en œuvre le Lean Startup. En savoir plus sur ce <Link to="/fr/quest-ce-que/lean-startup/">qu'est la Lean Startup</Link>.</p>
          
          <h2 className="pt-3">Types de start-ups par modèle d'entreprise</h2>
          <p>Les start-ups, et tout autre type d'entreprise, résolvent un problème donné à un groupe d'utilisateurs. Selon le secteur dans lequel ce problème est résolu, les entreprises en démarrage peuvent être divisées en, entre autres :
            <ul>
              <li><strong>Startups Fintech:</strong> développer leur solution en appliquant la <Link to="/fr/quest-ce-que/fintech-technologie-financiere/">technologie financière</Link>. Exemple : Wynd</li>
              <li><strong>Startups Foodtech:</strong> se consacrent à l'amélioration des processus liés à l'alimentation. Exemple : Hyperlex</li>
              <li><strong>Startups Legaltech:</strong> leur marché est le droit et la profession juridique. Exemple : TimeBillingX </li>
              <li><strong>Startups Healhtech:</strong> s'est concentré principalement sur le secteur des soins aux personnes âgées. Exemple : Doctolib</li>
              <li><strong>Startups Cleantech:</strong> automatiser les processus liés au nettoyage des textiles et des maisons. Exemple : Plastic Odyssey</li>
              <li><strong>Startups Edtech:</strong> créer des solutions innovantes dans le secteur de l'éducation. Exemple : Udemy</li>
              <li><strong>Startups Insurtech:</strong> offrent des services liés à l'assurance à leurs clients. Exemple : Luko</li>
              <li><strong>Startups Proptech:</strong> développer une technologie axée sur le secteur de l'immobilier. Exemple : ManoMano</li>
            </ul></p>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            
          <h2 className="pt-3">Classification des entreprises en phase de démarrage par pays</h2>
          Il existe des startups innovantes partout dans le monde. Voici quelques exemples de startups classées selon leur pays d'origine.
          <h3 className="pt-3">Les startups françaises</h3>
          <p>Le secteur technologique français est en plein essor. Cette année, la liste des 121 meilleures start-ups technologiques françaises est un mélange éclectique de scaleups à forte croissance, de petites start-ups créatives et de tout ce qui se trouve entre les deux.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={BlaBlaCar} alt="BlaBlaCar" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>BlaBlaCar</strong><br/>
                  voyage
                   <br/>
                  <FaLink /> https://www.blablacar.com &nbsp; <FaMapMarkerAlt /> Paris <br/>
                  Plate-forme de covoiturage longue distance qui met en relation les conducteurs ayant des sièges vides et les passagers pour partager les frais de déplacement
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={shadow} alt="shadow" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Shadow</strong><br/>
                  cloud <br/>
                  <FaLink /> https://shadow.tech/ &nbsp; <FaMapMarkerAlt /> Paris <br/>
                  Ordinateur à haute performance basé sur le nuage qui a une évolution matérielle automatisée.
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={meero} alt="meero" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Meero</strong><br/>
                  intelligence artificielle
                   <br/>
                  <FaLink /> https://www.meero.com &nbsp; <FaMapMarkerAlt /> Paris <br/>
                  Une société d'IA fournissant des services de photographie améliorés
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
          <h2>Derniers Articles du Blog</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/fr/blog/"><strong>Voir plus d'articles sur les Startups</strong></Link></p>
      </div>
    </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      limit:6
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: "fr" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
function getIndexPath(lang) {
  return lang === "es" ? "" : `${lang}/`
}

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
